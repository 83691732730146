import { InjectionToken } from '@angular/core';

export const CONTACT_US_PATH_TOKEN = new InjectionToken<string>('문의하기패스', {
  providedIn: 'root',
  factory: (): string => '/question'
});

export const LINK_PHONE_PATH_TOKEN = new InjectionToken<string>('링크 전화번호 패스', {
  providedIn: 'root',
  factory: (): string => '/link-phone'
});

export const PROXY_URL_TOKEN = new InjectionToken<string>('PROXY_URL_TOKEN', {
  providedIn: 'root',
  factory: (): string => 'https://proxy.pixelteam.io'
});
